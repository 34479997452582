import smooth from 'smooth-polyline';
import moment from 'moment';
import { transform } from 'ol/proj.js';
import { createPolygon } from '@ro-utils/common/map/newPolygons';
import { textMarker } from '@ro-utils/common/map/textmarker';

import { LAYER_ESTOFEX, LAYER_ESTOFEX_MARKER } from '@ro-utils/constant';

const estofexParams = {
  'severe storms unlikely': 0,
  '15thunder': 1,
  '50thunder': 2,
  'level 1': 3,
  'level 2': 4,
  'level 3': 5,
  mdiscuss: 6
};

const estofexTextMarkersParams = {
  thunder: {
    color: '#f9ff60',
    text: '',
    icon: '/f016'
  },
  'level 1': {
    color: '#ff6600',
    text: '1',
    icon: ''
  },
  'level 2': {
    color: '#ff0000',
    text: '2',
    icon: ''
  },
  'level 3': {
    color: '#ff00ff',
    text: '3',
    icon: '/f073'
  },
  mdiscuss: {
    color: '#00ff00',
    text: 'md',
    icon: '/f073'
  }
};

export const legend = {
  '0': {
    label: 'burza',
    short_description: {
      '0': '',
      '1': 'Ryzyko wystąpienia burzy - 15%',
      '2': 'Ryzyko wystąpienia burzy - 50%',
      '3': 'Ryzyko silnych burz',
      '4': 'Ryzyko bardzo silnych burz',
      '5': 'Ryzyko ekstremalnych burz',
      '6': 'System mezoskalowy - dyskusja'
    },
    description: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': ''
    },
    strokeWidth: {
      '0': 1,
      '1': 1,
      '2': 2,
      '3': 2,
      '4': 2,
      '5': 2,
      '6': 2
    },
    color: {
      '0': '#f9ff60',
      '1': '#f9ff60',
      '2': '#f9ff60',
      '3': '#ff6600',
      '4': '#9c0204',
      '5': '#ff00ff',
      '6': '#00ff00'
    },
    icon: {
      '0': 'lightning',
      '1': 'lightning',
      '2': 'lightning',
      '3': 'lightning',
      '4': 'lightning',
      '5': 'hurricane',
      '6': 'hurricane'
    }
  }
};

export const estofexTranslator = (data, sp) => {
  const format = 'YYYYMMDDHH';
  let realArea;
  const area = data.forecast && data.forecast.area;
  if (area && area.length) {
    realArea = area;
  }
  if (area && area._attributes) {
    realArea = [area];
  }

  const estofexObject = data &&
    data.forecast && {
      expiryTime: data.forecast.expiry_time._attributes.value,
      startTime: data.forecast.start_time._attributes.value,
      forecaster: data.forecast.forecaster._text,
      area:
        area &&
        realArea.map(item => {
          return {
            validTo: moment(data.forecast.expiry_time._attributes.value, format).unix(),
            validFrom: moment(data.forecast.start_time._attributes.value, format).unix(),
            points:
              item.point &&
              item.point.map(point => ({
                lat: Number(point._attributes.lon), //todo => replace lat and lon in the job
                lon: Number(point._attributes.lat)
              })),
            level: estofexParams[item._attributes.risktype],
            type: 0
          };
        }),
      marker:
        data.forecast.marker.length &&
        data.forecast.marker.map(item => {
          return {
            lat: Number(item._attributes.lat),
            lon: Number(item._attributes.lon),
            ...estofexTextMarkersParams[item._attributes.type]
          };
        })
    };
  return estofexObject;
};

export const makeEstofexMap = function(data, description, map, settings) {
  const estofexData = data.area;
  const estofexMarkerData = data.marker;
  const estofexDescription = description;

  const features = [];
  let optionals;

  estofexData &&
    estofexData.forEach(function(el, i) {
      const type = estofexDescription[el.type];
      const path = [];
      const param = name => type[name] && type[name][el.level];
      const makeSmooth = (path, numIterations) => {
        numIterations = Math.min(Math.max(numIterations, 1), 10);
        while (numIterations > 0) {
          path = smooth(path);
          numIterations--;
        }
        return path;
      };
      el.points &&
        el.points.forEach(function(point) {
          path.push(transform([point.lat, point.lon], 'EPSG:4326', 'EPSG:3857'));
        });

      const smoothenedPath = makeSmooth(path, 2);
      optionals = {
        desc: param('description'),
        shortDesc: param('short_description'),
        validFrom: el.validFrom,
        validTo: el.validTo,
        priority: el.level,
        identify: type['label'] + '_' + el.level,
        UIType: LAYER_ESTOFEX,
        roUIID: `${LAYER_ESTOFEX}_${el.type}`,
        color: param('color'),
        icon: param('icon'),
        title: type['label'],
        source: 'estofex.org',
        link: 'http://www.estofex.org/'
      };

      const polygonConfig = {
        UIType: LAYER_ESTOFEX,
        path: smoothenedPath,
        frame: true,
        opacityFill: parseInt(settings.opacityFill, 10) / 100,
        opacityStroke: parseInt(settings.opacityStroke, 10) / 100,
        color: param('color'),
        strokeWidth: param('strokeWidth'),
        zIndex: el.level,
        optional_params: optionals,
        layerIndex: settings.index
      };
      features.push(polygonConfig);
    });

  createPolygon(features, map, LAYER_ESTOFEX, optionals);

  estofexMarkerData &&
    textMarker({
      item: estofexMarkerData,
      type: 'text',
      offset: 15,
      unit: false,
      uitype: LAYER_ESTOFEX_MARKER,
      fontSize: '30px',
      asFill: true,
      fontWeight: 'normal',
      fontFamily: 'verdana',
      layerIndex: settings.index
    });
};
