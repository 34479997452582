import { deepParseJson } from 'deep-parse-json';

export const polling = ({ eventType, onReceive, ext }) => {
  const loc = window.location;
  return fetch(`${loc.protocol}//${loc.host}/data/${eventType}.${ext}`)
    .then(result => {
      if (result.ok) {
        switch (ext) {
          case 'xml': {
            return result.text();
          }
          case 'json': {
            return result.json();
          }
          case 'geojson': {
            return result.json();
          }
          default: {
            break;
          }
        }
      }
    })
    .then(result => {
      return onReceive(deepParseJson(result));
    });
};
