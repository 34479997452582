export const defaultLegendParams = {
    cmax: {
        unit: 'dBz',
        icon: {
          weather: true,
          name: 'raindrop'
        },
        unitText: 'Siła opadu',
    },
    cappi: {
        unit: 'dBz',
        unitText: 'Siła opadu',
        icon: {
          weather: true,
          name: 'raindrop'
        },
    },
    pac: {
        unit: 'mm/godz',
        unitText: 'Suma opadu',
        icon: {
          weather: true,
          name: 'flood'
        },
    },
    sri: {
        unit: 'mm/godz',
        unitText: 'Natężenie opadu',
        icon: {
          weather: true,
          name: 'raindrop'
        },
    },
    eht: {
        unit: 'km',
        unitText: 'Wierzchołek chmur',
        icon: {
          weather: true,
          name: 'cloud-up'
        },
    },
    zhail: {
        unit: '%',
        unitText: 'Ryzyko gradu',
        icon: {
          weather: true,
          name: 'meteor'
        },
    }
  };