// import * as lightningsData from '@ro-data/imgw/files_maps/lightnings.json';
import moment from 'moment';
import { RADAR_FORMAT, STORAGE_NAME } from '@ro-utils/constant';

import {
  LAYER_RADAR,
  LAYER_ESTOFEX,
  LAYER_SKYPREDICT,
  LAYER_SNOW_COVER,
  LAYER_SYNOP,
  LAYER_DETECTOR
} from '@ro-utils/constant';

const format = RADAR_FORMAT;
const pathname = window.location.pathname;

const foundedDate = pathname.split(/[/~]/).filter(item => moment(item, format, true).isValid() && item)[0];

const currentTime = foundedDate;
const isValid = moment(currentTime, format, true).isValid();
const date = moment(currentTime, format);
const userSettingsStorage = localStorage.getItem(STORAGE_NAME);

const defaultUserSettings = {
  oldRadarModal: true,
  tutorialLevel: 10,
  tile: 'OSM',
  synopType: 'temperatura',
  geolocation: false,
  radar: {
    noSlides: 36,
    opacity: [40],
    scanSmoothing: false
  },
  estofex: {
    opacityStroke: [80],
    opacityFill: [5]
  },
  lightnings: {
    opacity: [100],
    interpolation: ['#ff0000', '#fff'],
    pointSize: 7
  },
  layersOrder: [LAYER_SYNOP, LAYER_SNOW_COVER, LAYER_SKYPREDICT, LAYER_ESTOFEX, LAYER_DETECTOR, LAYER_RADAR],
  layersIndex: {
    synop: 5,
    snow: 4,
    skypredict: 3,
    estofex: 2,
    detector: 1,
    radar: 0
  },
  favoriteLocations: [],
  sp: {
    opacityFill: [5],
    opacityStroke: [80]
  },
  shortcuts: {
    radarOptions: true,
    spOptions: false,
    estofexOptions: false,
    lightningsOptions: true,
    map: false,
    layersVisibilities: true,
    synopOptions: false,
    orderOptions: false
  },
  visibility: {
    radar: true,
    sp: true,
    estofex: true,
    tooltip: true,
    lightnings: true,
    snow: false,
    synop: false
  },
  anchor: false
};

export const initialState = {
  query: {
    radartype: 'cappi',
    date: false,
    location: false
  },
  currentRotation: 0,
  queryString: '',
  helpModal: false,
  tutorialStarted: false,
  animation: false,
  mapType: 'cappi',
  radarData: false,
  skyPredictData: false,
  estofexData: false,
  snowCoverData: false,
  synopData: false,
  currentEstofexData: false,
  currentData: false,
  archive: false,
  date: isValid ? date : false,
  lightningsData: false,
  legend: {},
  radarDataImages: false,
  sliderVis: true,
  userSettings: JSON.parse(userSettingsStorage) || defaultUserSettings,
  currentLocation: {},
  mapLoaded: false,
  currentScan: false,
  ol: {
    tile: {
      opacity: 1,
      preload: Infinity
    },
    radar: {
      modes: {
        old: {
          epsg: 'EPSG:2180',
          extent: [0, 0, 902850, 901000],
          transform: '+proj=tmerc +lat_0=2.690 +lon_0=19.042 +k=0.9993 +x_0=448000 +y_0=-5050000 +units=m +defs'
        },
        new: {
          epsg: 'EPSG:3857',
          extent: [1315006.01, 6129076.6, 2800531.22, 7595632.47],
          transform: false
        }
      }
    },
    view: {
      center: [19.36, 52.18],
      zoom: 6,
      minZoom: 5,
      maxZoom: 20,
      projection: 'EPSG:3857'
    },
    map: {
      target: 'chase-map',
      controls: []
    }
  }
};
