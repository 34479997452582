import { init } from '@rematch/core';
import * as models from './model';
import { InitConfig } from './Store.types';
import { Dispatch } from 'modules';

const initConfig: InitConfig = {
  models,
  name: 'radar-opadow.pl'
};
const store = init(initConfig);
export const dispatch: Dispatch = store.dispatch;
export default store;
