import chroma from 'chroma-js';
import uniq from 'ramda/src/uniq';
import unnest from 'ramda/src/unnest';
import repeat from 'ramda/src/repeat';
import zipObj from 'ramda/src/zipObj';
import zip from 'ramda/src/zip';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import moment from 'moment-timezone';

import radarParams from './radarLegends/radarLegends';

export const rgb2hex = rgb => {
  rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return rgb && rgb.length === 4
    ? '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
    : '';
};

export const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
};

export const withTimezone = (date, format) => {
  const zone = parseInt(
    moment(date, format)
      .tz('Europe/Warsaw')
      .format('Z'),
    10
  );
  const newDate = moment(date, format)
    .add(zone, 'hours')
    .format(format);

  return newDate;
};

export const getDbz = (legend, color) => {
  let interpolatedColorArray = [];
  for (var i = 0; i < legend.length; i++) {
    interpolatedColorArray.push(chroma.distance(legend[i], rgb2hex(color)));
  }
  return interpolatedColorArray;
};

export const arrayMin = array => {
  return Math.min.apply(Math, array);
};

export const arrayMax = array => {
  return Math.max.apply(Math, array);
};

export const makeDbz = (color, scale) => {
  const dst = getDbz(scale, color);
  const minimum = arrayMin(dst);
  const colorId = dst.indexOf(minimum);
  return {
    colorId,
    array: scale
  };
};

export const getDistance = (color, scale) => {
  const colorIds = makeDbz(color, scale);
  const perturbations = [colorIds.colorId];
  return {
    dbz: arrayMax(perturbations) / 10,
    color: colorIds.array[arrayMax(perturbations)]
  };
};

Array.range = (from, to, step) => Array.from({ length: Math.floor((to - from) / step) + 1 }, (v, k) => from + k * step);

const createLegend = (legend, length, description) => {
  let values = [];
  let names = [];
  const countRanges = (point, i, allPoints) => {
    const next = parseInt(i, 10) + 1;
    const distance = allPoints[next] - point;
    const middlePoint = point + distance;
    const points = Array.range(point, allPoints[next], distance / length);
    const desc = description && repeat(description[parseInt(i, 10)], points.length);
    const objectedPoints = [];
    const valToObject = (val, i) => {
      objectedPoints.push({
        value: val[0] !== undefined ? val[0] : val,
        description: val[1]
      });
    };
    forEachObjIndexed(valToObject, description ? zip(points, desc) : points);
    values.push(objectedPoints);
    middlePoint && names.push(middlePoint);
  };
  forEachObjIndexed(countRanges, legend);

  return {
    values,
    names
  };
};

const createInterpolation = (radarParams, length) => {
  let scale = [];
  const interpolation = (color, i, array) => {
    const nextColor = array[parseInt(i, 10) + 1];
    const pair = [color, nextColor];
    nextColor &&
      scale.push(
        nextColor &&
          chroma
            .scale(pair)
            .mode('lch')
            .colors(length)
      );
  };
  forEachObjIndexed(interpolation, radarParams);
  return scale;
};

const switchLegend = legendTime => {
  switch (true) {
    case !legendTime: {
      return 'default';
    }
    case legendTime <= 1522792800000: {
      return 1522792800000;
    }
    case legendTime <= 1551652549000: {
      return 1551652549000;
    }
    case legendTime <= 1638317339000: {
      return 1638317339000;
    }
    default: {
      return 'default';
    }
  }
};

export const getCurrentLegend = (mapType, legendTime) => {
  const legend = radarParams[mapType][switchLegend(legendTime)];
  const legendProps = createLegend(legend.legend, 20, legend.description);
  const legendColors = uniq(unnest(createInterpolation(legend.hex, 21)));
  const legendValues = uniq(unnest(legendProps.values));
  const legendMap = zipObj(legendColors, legendValues);
  const legendUnit = legend.unit;
  const legendIcon = legend.icon;
  const legendUnitText = legend.unitText;
  const mode = legend.mode;
  const dir = legend.dir;
  return {
    legendProps,
    legendColors,
    legendValues,
    legendMap,
    legendUnit,
    legendUnitText,
    legendIcon,
    mode,
    dir
  };
};
