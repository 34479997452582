import { AppContainer } from 'react-hot-loader';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { render } from 'react-dom';
import '@ro-styles/main.scss';
import 'moment/locale/pl';
import { Provider } from 'react-redux';
import App from '@ro-containers/App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import store from '@ro-store';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import common_pl from '@ro-translations/pl/common.json';
import common_en from '@ro-translations/en/common.json';

import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/styles';

const generateClassName = createGenerateClassName();

const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point'
});

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pl',
  resources: {
    pl: {
      common: common_pl
    },
    en: {
      common: common_en
    }
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", serif',
    fontSize: 12
  }
});
render(
  <AppContainer>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </JssProvider>
      </Provider>
    </MuiThemeProvider>
  </AppContainer>,
  document.getElementById('app')
);
