import React from 'react';
import { PropagateLoader } from 'react-spinners';
import image from '@ro-images/logo.png';
import { Wrapper, Logo } from './Preloader.styles';
import { Props } from './Preloader.types';

const Preloader = (props: Props) => (
  <Wrapper overlay={props.overlay} alpha={props.alpha}>
    <div style={{ height: 120 }}>
      <Logo src={image} alt="logo" />
    </div>
    <PropagateLoader color={'#fff'} size={10} />
  </Wrapper>
);
export default Preloader;
