import styled, { css } from 'styled-components';
import { Props } from './Preloader.types';

export const Wrapper = styled.div<Props>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  ${props =>
    props.overlay === 'black' &&
    css`
      background: rgba(0, 0, 0, ${props.alpha});
    `};
`;
export const Logo = styled.img`
  width: 240px;
  margin-bottom: 30px;
`;
