import { getCoordFromDms, toTitleCase } from '@ro-utils/common/helpers';

const checkCover = (cover: string) => {
  const parsed = Number(cover).toFixed(0);
  return parsed === 'NaN' ? '0' : parsed;
};

export interface Tr {
  '@attributes': {
    title: string;
  };
  td: string[];
}

export interface SnowData {
  tr: Tr[];
}

export interface PreparedSnowData {
  lat: number;
  lon: number;
  altitude: string;
  place: string;
  snow: string;
  freshSnow: string;
  snowType: string;
  region: string;
  color: string;
}

export const prepareSnowData = (data: SnowData) => {
  const snowSynop =
    data &&
    data.tr &&
    data.tr.length &&
    data.tr.map(item => {
      const title = item['@attributes'].title.split(',');
      const lat = getCoordFromDms(title[0]);
      const lon = getCoordFromDms(title[1]);
      const data: PreparedSnowData = {
        lat,
        lon,
        altitude: title[2].replace(/\s/g, ''),
        place: toTitleCase(item.td[0]),
        snow: checkCover(item.td[2]),
        freshSnow: checkCover(item.td[3]),
        snowType: item.td[4] === 'X' ? 'b.d.' : item.td[4],
        region: item.td[5],
        color: '#fff'
      };
      return data;
    });
  return snowSynop;
};
