import { initialState } from './schema';
import lensPath from 'ramda/src/lensPath';
import set from 'ramda/src/set';
import view from 'ramda/src/view';
import moment from 'moment';
import remove from 'ramda/src/remove';
import { STORAGE_NAME } from '@ro-utils/constant';
import { dispatch } from '@ro-store';
import { RADAR_FORMAT } from '@ro-utils/constant';
import { getUrlString } from '@ro-utils/common/helpers';
import { withTimezone } from '@ro-utils/common/radar-params';
import convert from 'xml-js';
import { estofexTranslator } from '@ro-components/Estofex/utils';

const updateProp = (path, updateStorage) => (state, payload) => {
  const newState = { ...state };
  const lens = lensPath(path);
  const reducedState = set(lens, payload, newState);
  updateStorage && localStorage.setItem(STORAGE_NAME, JSON.stringify(reducedState.userSettings));
  return reducedState;
};

const addItem = (path, updateStorage) => (state, payload) => {
  const lens = lensPath(path);
  const newState = { ...state };
  const items = [...view(lens, state)];
  items.push(payload);
  const reducedState = set(lens, items, newState);
  updateStorage && localStorage.setItem(STORAGE_NAME, JSON.stringify(reducedState.userSettings));
  return reducedState;
};

const removeItem = (path, updateStorage) => (state, index) => {
  const lens = lensPath(path);
  const newState = { ...state };
  const items = [...view(lens, state)];
  const reducedItems = remove(index, 1, items);
  const reducedState = set(lens, reducedItems, newState);
  updateStorage && localStorage.setItem(STORAGE_NAME, JSON.stringify(reducedState.userSettings));
  return reducedState;
};

export const ro = {
  state: initialState,
  reducers: {
    setCurrentRotation: updateProp(['currentRotation']),
    setLightnings: updateProp(['lightningsData']),
    setSkyPredictData: updateProp(['skyPredictData']),
    setEstofexData: updateProp(['estofexData']),
    setCurrentEstofexData: updateProp(['currentEstofexData']),
    setCurrentRadarData: updateProp(['currentData']),
    setRadarData: updateProp(['radarData']),
    setAnimation: updateProp(['animation']),
    setSnowCoverData: updateProp(['snowCoverData']),
    setSynopData: updateProp(['synopData']),
    setDuration: updateProp(['userSettings', 'animationSpeed'], true),
    setCurrentLocation: updateProp(['currentLocation']),
    setLegendProperties: updateProp(['legend']),
    setDate: (state, payload) => updateProp(['date'])(state, payload.date),
    setMapType: (state, payload) => updateProp(['mapType'])(state, payload.radartype),
    createEstofexShortcut: updateProp(['userSettings', 'shortcuts', 'estofexOptions'], true),
    estofexOpacityStrokeChange: updateProp(['userSettings', 'estofex', 'opacityStroke'], true),
    estofexOpacityFillChange: updateProp(['userSettings', 'estofex', 'opacityFill'], true),
    createSpShortcut: updateProp(['userSettings', 'shortcuts', 'spOptions'], true),
    spOpacityStrokeChange: updateProp(['userSettings', 'sp', 'opacityStroke'], true),
    spOpacityFillChange: updateProp(['userSettings', 'sp', 'opacityFill'], true),
    changeLayerState: (state, { path, value }) => updateProp(path, true)(state, value),
    toggleAnchor: updateProp(['userSettings', 'anchor'], true),
    createLayerShortcut: updateProp(['userSettings', 'shortcuts', 'layersVisibilities'], true),
    createLightningsShortcut: updateProp(['userSettings', 'shortcuts', 'lightningsOptions'], true),
    setLightningsInterpolation: updateProp(['userSettings', 'lightnings', 'interpolation'], true),
    lightningsOpacityChange: updateProp(['userSettings', 'lightnings', 'opacity'], true),
    setLightningsPointSize: updateProp(['userSettings', 'lightnings', 'pointSize'], true),
    createOrderShortcut: updateProp(['userSettings', 'shortcuts', 'orderOptions'], true),
    createSynopShortcut: updateProp(['userSettings', 'shortcuts', 'synopOptions'], true),
    changeTileLayer: updateProp(['userSettings', 'tile'], true),
    changeSynopType: updateProp(['userSettings', 'synopType'], true),
    createMapShortcut: updateProp(['userSettings', 'shortcuts', 'map'], true),
    createRadarShortcut: updateProp(['userSettings', 'shortcuts', 'radarOptions'], true),
    noSlidesChange: updateProp(['userSettings', 'radar', 'noSlides'], true),
    smoothChange: updateProp(['userSettings', 'radar', 'scanSmoothing'], true),
    radarOpacityChange: updateProp(['userSettings', 'radar', 'opacity'], true),
    addFavoriteLocation: addItem(['userSettings', 'favoriteLocations'], true),
    removeFavoriteLocation: removeItem(['userSettings', 'favoriteLocations'], true),
    setTutorialLevel: updateProp(['userSettings', 'tutorialLevel'], true),
    setTutorialStartFlag: updateProp(['tutorialStarted']),
    setHelpModal: updateProp(['helpModal']),
    setGeolocation: updateProp(['userSettings', 'geolocation'], true),
    setQuery: (state, payload) => updateProp(['query', payload.key])(state, payload.value),
    setLastLocation: (state, payload) => updateProp(['query', payload.key])(state, payload.value),
    setQueryString: updateProp(['queryString']),
    loading: updateProp(['loading']),
    setLayersOrder: updateProp(['userSettings', 'layersOrder'], true),
    setLayersIndex: updateProp(['userSettings', 'layersIndex'], true),
    closeOldRadarModal: updateProp(['userSettings', 'oldRadarModal'], false),
    closeOldRadarModalPermanently: updateProp(['userSettings', 'oldRadarModal'], true)
  },
  effects: {
    setCurrentLocation: payload => {
      const { original, zoom } = payload;
      const url = original && zoom ? encodeURI(`${original[0]}|${original[1]}|${zoom || 8}`) : false;
      dispatch.ro.setQuery({
        value: url,
        key: 'location',
        noEffect: payload.noEffect
      });
    },
    setDate: (payload, state) => {
      dispatch.ro
        .setQuery({
          value: payload.date,
          key: 'date',
          noEffect: payload.noEffect
        })
        .then(() => {
          if (payload.date) {
            dispatch.ro.loading(true);
            const d = moment(payload.date).format('YYYY-MM-DD');
            const date = moment(d, 'YYYY-MM-DD');
            const offset = parseInt(withTimezone(date, 'HH'), 5);
            let points = [];

            for (var i = 0; i < 288; i++) {
              points.push(
                moment(date)
                  .add(i * 5 - offset * 60, 'minutes')
                  .format(RADAR_FORMAT)
              );
            }

            dispatch.ro.setRadarData({
              cappi: points,
              cmax: points,
              eht: points,
              pac: points,
              sri: points,
              zhail: points
            });

            const estofexDate = date.format('DD-MM-YYYY');
            const estofexUrlSlug = estofexDate && `estofex-${estofexDate}.xml`;
            const url = `https://old.radar-opadow.pl/data/estofex/archive/${estofexUrlSlug}`;
            new Promise((resolve, reject) => {
              return fetch(url).then(
                response => {
                  if (response.ok) {
                    response.text().then(data => {
                      const newData =
                        data &&
                        data
                          .replace(/<text>.*<\/text>/, '')
                          .replace(/<BR>/g, '<br/>')
                          .replace('&begin=', '');
                      const estofexData = estofexTranslator(
                        JSON.parse(convert.xml2json(newData, { compact: true, spaces: 4 }))
                      );
                      dispatch.ro
                        .setEstofexData({
                          ...estofexData,
                          description: data.match(new RegExp('<text>(.*)</text>'))[1]
                        })
                        .then(() => {
                          dispatch.ro.loading(false);
                        });
                    });
                  } else {
                    dispatch.ro.setEstofexData(false);
                    dispatch.ro.loading(false);
                  }
                },
                error => {
                  dispatch.ro.setEstofexData(false);
                  dispatch.ro.loading(false);
                }
              );
            });
          }
        });
    },
    setLayersOrder: payload => {
      let layersIndex = {};
      [...payload].reverse().map((item, index) => {
        layersIndex[item] = index;
        return false;
      });
      dispatch.ro.setLayersIndex(layersIndex);
    },
    setMapType: (payload, state) => {
      dispatch.ro.setQuery({
        value: payload.radartype,
        key: 'radartype',
        noEffect: payload.noEffect
      });
    },
    setQuery: (payload, state) => {
      const { key, value, noEffect } = payload;
      const query = {
        ...state.ro.query,
        [key]: value
      };
      const urlString = getUrlString(query);
      dispatch.ro.setQueryString(urlString.queryString);
      !noEffect &&
        window.history.pushState &&
        window.history.pushState(
          {
            path: urlString.fullUrl
          },
          '',
          urlString.fullUrl
        );
    }
  }
};
