import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import convert from 'xml-js';
import { polling } from '@ro-utils/common/pollingService';
import JssProvider from 'react-jss/lib/JssProvider';

import { getTimeDiff } from '@ro-utils/common/helpers';
import { withTimezone } from '@ro-utils/common/radar-params';
import { estofexTranslator } from '@ro-components/Estofex/utils';
import { prepareSnowData } from '@ro-utils/common/snow';
import { prepareSynopData } from '@ro-utils/common/synop';
import { translate } from 'react-i18next';
import moment from 'moment';
import '@ro-styles/main.scss';
import { LIGHTNINGS_DATEFORMAT } from '@ro-utils/constant';

import { dispatch } from '@ro-store';
import Preloader from '@ro-atoms/Preloader';

const MapContainer = lazy(() => import('./MapContainer'));
const ControlsContainer = lazy(() => import('./ControlsContainer'));
const MenuContainer = lazy(() => import('./MenuContainer'));
const SettingsRoot = lazy(() => import('./SettingsRoot'));
const MapSettings = lazy(() => import('./Settings/Map'));
const WarningCenter = lazy(() => import('./WarningCenter'));
const Synop = lazy(() => import('./Synop'));
const Snow = lazy(() => import('./Snow'));
const Meteogram = lazy(() => import('./Meteogram'));
const SkyPredictSettings = lazy(() => import('./Settings/SkyPredict'));
const EstofexSettings = lazy(() => import('./Settings/Estofex'));
const SynopSettings = lazy(() => import('./Settings/Synop'));
const LightningsSettings = lazy(() => import('./Settings/Lightnings'));
const RadarSettings = lazy(() => import('./Settings/Radar'));
const OrderSettings = lazy(() => import('./Settings/Order'));
const LayersSettings = lazy(() => import('./Settings/Layers'));
const Geodecoder = lazy(() => import('./Geodecoder'));
const Licenses = lazy(() => import('./Licenses'));
const Faq = lazy(() => import('./Faq'));

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
let classCounter = 0;

export const generateClassName = (rule, styleSheet) => {
  classCounter += 1;

  if (process.env.NODE_ENV === 'production') {
    return `c${classCounter}`;
  }

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix;
    prefix = prefix.replace(escapeRegex, '-');

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`;
    }

    return `${prefix}-${rule.key}-${classCounter}`;
  }

  return `${rule.key}-${classCounter}`;
};

const routes = {
  menu: MenuContainer,
  map: MapSettings,
  radar: RadarSettings,
  skypredict: SkyPredictSettings,
  estofex: EstofexSettings,
  detector: LightningsSettings,
  order: OrderSettings,
  visibilities: LayersSettings,
  synop: SynopSettings,
  settings: SettingsRoot,
  licenses: Licenses,
  'warning-center': WarningCenter,
  'synop-detail': Synop,
  snow: Snow,
  meteogram: Meteogram,
  faq: Faq,
  search: Geodecoder
};

const setRadarData = data => {
  dispatch.ro.setRadarData(data).then(() =>
    setTimeout(() => {
      dispatch.ro.loading(false);
    }, 200)
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      fetched: false
    };
  }
  setPulledData = no_lightnings => {
    polling({
      eventType: 'radarData_3857',
      ext: 'json',
      onReceive: data => {
        data &&
          dispatch.ro.setCurrentRadarData(data).then(() => {
            dispatch.ro.loading(false);
          });
      }
    });

    if (!no_lightnings) {
      polling({
        eventType: 'lightnings',
        ext: 'geojson',
        onReceive: data => {
          if (data && data.range) {
            const flat = {
              updateTime: data.updateTime,
              range: data.range,
              data:
                data.features &&
                data.features.map(item => [
                  [item.properties.longitude, item.properties.latitude],
                  item.properties.date,
                  item.properties.distance
                ])
            };
            dispatch.ro.setLightnings(flat).then(() => {
              dispatch.ro.loading(false);
            });
          }
        }
      });
    }

    polling({
      eventType: 'skyPredict',
      ext: 'json',
      onReceive: data => {
        data &&
          dispatch.ro.setSkyPredictData(data).then(() => {
            dispatch.ro.loading(false);
          });
      }
    });
    polling({
      eventType: 'snow_cover',
      ext: 'json',
      onReceive: data => {
        data &&
          dispatch.ro.setSnowCoverData(prepareSnowData(data)).then(() => {
            dispatch.ro.loading(false);
          });
      }
    });
    polling({
      eventType: 'synop',
      ext: 'json',
      onReceive: data => {
        data &&
          dispatch.ro.setSynopData(prepareSynopData(data)).then(() => {
            dispatch.ro.loading(false);
          });
      }
    });
    polling({
      eventType: 'forecast',
      ext: 'xml',
      onReceive: data => {
        if (data) {
          const newData = data
            .replace(/<text>.*<\/text>/, '')
            .replace(/<BR>/g, '<br/>')
            .replace('&begin=', '');
          const estofexData = estofexTranslator(JSON.parse(convert.xml2json(newData, { compact: true, spaces: 4 })));
          dispatch.ro
            .setCurrentEstofexData({
              ...estofexData,
              description: data.match(new RegExp('<text>(.*)</text>'))[1]
            })
            .then(() => {
              dispatch.ro.loading(false);
            });
        }
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    const { date } = this.props;
    const { firstLoad, fetched } = this.state;

    const { date: newDate, currentData } = nextProps;
    if (!fetched) {
      this.setPulledData(true);

      this.setState({
        fetched: true
      });
      polling({
        eventType: 'lightnings',
        ext: 'geojson',
        onReceive: data => {
          if (data && data.range) {
            const flat = {
              updateTime: data.updateTime,
              range: data.range,
              data:
                data.features &&
                data.features
                  .map(item => [
                    [item.properties.longitude, item.properties.latitude],
                    item.properties.date,
                    item.properties.distance
                  ])
                  .reverse()
            };
            const now = moment().format(LIGHTNINGS_DATEFORMAT);
            const updateTime = withTimezone(data.updateTime, LIGHTNINGS_DATEFORMAT);
            const updateOffset = getTimeDiff(updateTime, now) + 5;

            setTimeout(() => {
              this.setPulledData();
              setInterval(this.setPulledData, 60000);
            }, (60 + updateOffset) * 1000);

            dispatch.ro.setLightnings(flat).then(() => {
              dispatch.ro.loading(false);
            });
          }
        }
      });
    }
    if ((date !== newDate && !newDate) || (!newDate && !firstLoad)) {
      dispatch.ro.loading(true);
      setRadarData(currentData);
      currentData &&
        this.setState({
          firstLoad: true
        });
    }
  }
  render() {
    const { t } = this.props;
    return (
      <JssProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <Suspense fallback={<Preloader alpha="1" overlay="black" position="fixed" />}>
            <MapContainer />
            <ControlsContainer />
            <Switch>
              {Object.keys(routes).map((route, i) => {
                return <Route key={i} path={t(`routes.${route}`)} component={routes[route]} />;
              })}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </JssProvider>
    );
  }
}

const mapStateToProps = ({ ro }) => ro;

export default connect(mapStateToProps)(translate('common')(App));
