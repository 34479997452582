export const legend_1638317339000 = {
  cmax: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'silny',
      'silny',
      'ulewny',
      'ulewny',
      'ulewny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny'
    ],
    legend: [5, 8, 10.9, 14, 17.1, 20, 23, 27.8, 30.6, 32.6, 34.2, 37.6, 40.3, 43.8, 46.6, 50.2, 53, 56.3, 59.1, 62],
    hex: [
      '#0707C8',
      '#1261FF',
      '#00B4FF',
      '#3CCDFF',
      '#78E6FF',
      '#BBF2FF',
      '#ffffff',
      '#FFF799',
      '#FFEE33',
      '#FFCB1A',
      '#FF7300',
      '#FF1E00',
      '#C80000',
      '#AF0014',
      '#960028',
      '#BE0078',
      '#D6119B',
      '#EE23BE',
      '#F646D2',
      '#FF69E6',
    ]
  },
  cappi: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'silny',
      'silny',
      'ulewny',
      'ulewny',
      'ulewny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny'
    ],
    legend: [5, 8, 10.9, 14, 17.1, 20, 23, 27.8, 30.6, 32.6, 34.2, 37.6, 40.3, 43.8, 46.6, 50.2, 53, 56.3, 59.1, 62],
    hex: [
      '#0707C8',
      '#1261FF',
      '#00B4FF',
      '#3CCDFF',
      '#78E6FF',
      '#BBF2FF',
      '#ffffff',
      '#FFF799',
      '#FFEE33',
      '#FFCB1A',
      '#FF7300',
      '#FF1E00',
      '#C80000',
      '#AF0014',
      '#960028',
      '#BE0078',
      '#D6119B',
      '#EE23BE',
      '#F646D2',
      '#FF69E6',
    ]
  },
  pac: {
    legend: [0.075, 0.12, 0.17, 0.3, 0.4, 0.7, 1, 2, 3, 4, 5, 8, 12, 20, 30, 50, 75, 120],
    hex: [
      '#0707C8',
      '#1261FF',
      '#00B4FF',
      '#3CCDFF',
      '#78E6FF',
      '#BBF2FF',
      '#ffffff',
      '#FFF799',
      '#FFEE33',
      '#FFCB1A',
      '#FF7300',
      '#FF1E00',
      '#C80000',
      '#AF0014',
      '#960028',
      '#BE0078',
      '#D6119B',
      '#EE23BE',
    ]
  },
  sri: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'silny',
      'silny',
      'ulewny',
      'nawalny',
      'nawalny',
      'nawalny'
    ],
    legend: [0.07, 0.12, 0.18, 0.28, 0.43, 0.65, 1, 2, 3, 4, 5, 8, 12, 20, 30, 50],
    hex: [
      '#0707C8',
      '#1261FF',
      '#00B4FF',
      '#3CCDFF',
      '#78E6FF',
      '#BBF2FF',
      '#ffffff',
      '#FFF799',
      '#FFEE33',
      '#FFCB1A',
      '#FF7300',
      '#FF1E00',
      '#C80000',
      '#AF0014',
      '#960028',
      '#BE0078',
    ]
  },
  eht: {
    legend: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    hex: [
      '#0000C7',
      '#0079FF',
      '#32C8FF',
      '#78EBFF',
      '#FEFEFE',
      '#FFF7C0',
      '#FFE837',
      '#FF7300',
      '#FF3F00',
      '#CE0600',
      '#AB0000',
      '#800000',
    ]
  },
  zhail: {
    description: ['niskie', 'niskie', 'niskie', 'średnie', 'średnie', 'średnie', 'wysokie', 'wysokie', 'wysokie'],
    legend: [10, 20, 30, 40, 50, 60, 70, 80, 90],
    hex: ['#0000C7', '#0079FF', '#3EBAFF', '#E1F5FF', '#FFE500', '#FFAA00', '#FF2419', '#C50000', '#800000']
  }
};