export const legend_1522792800000 = {
  cmax: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'silny',
      'ulewny',
      'ulewny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny'
    ],
    legend: [0, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50],
    hex: [
      '#00007D',
      '#0000C7',
      '#0050EC',
      '#0079FF',
      '#32c8ff',
      '#60dfff',
      '#78ebff',
      '#ffffff',
      '#fffad5',
      '#fff7c0',
      '#ffe500',
      '#ff9900',
      '#ff7300',
      '#ff3f00',
      '#db1500',
      '#c80000',
      '#800000'
    ],
  },
  cappi: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'silny',
      'ulewny',
      'ulewny',
      'nawalny',
      'nawalny',
      'nawalny',
      'nawalny'
    ],
    legend: [0, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50],
    hex: [
      '#00007D',
      '#0000C7',
      '#0050EC',
      '#0079FF',
      '#32c8ff',
      '#60dfff',
      '#78ebff',
      '#ffffff',
      '#fffad5',
      '#fff7c0',
      '#ffe500',
      '#ff9900',
      '#ff7300',
      '#ff3f00',
      '#db1500',
      '#c80000',
      '#800000'
    ],
  },
  pac: {
    legend: [0.1, 0.2, 0.3, 0.6, 1.0, 1.7, 3.1, 5.4, 9.6, 17, 30],
    hex: [
      '#0000c7',
      '#0079ff',
      '#32c8ff',
      '#78ebff',
      '#ffffff',
      '#fff7c0',
      '#ffe500',
      '#ff7300',
      '#ff3f00',
      '#c80000',
      '#800000'
    ]
  },
  sri: {
    description: [
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'słaby',
      'umiarkowany',
      'umiarkowany',
      'silny',
      'ulewny',
      'nawalny',
      'nawalny'
    ],
    legend: [0.1, 0.2, 0.3, 0.6, 1.0, 1.7, 3.1, 5.4, 9.6, 17, 30],
    hex: [
      '#0000c7',
      '#0079ff',
      '#32c8ff',
      '#78ebff',
      '#ffffff',
      '#fff7c0',
      '#ffe500',
      '#ff7300',
      '#ff3f00',
      '#c80000',
      '#800000'
    ]
  },
  eht: {
    legend: [0, 1.25, 2.5, 3.75, 5, 6.25, 7.5, 8.75, 10, 11.25, 12.5, 13.75, 15, 18.25, 21],
    hex: [
      '#0000C7',
      '#0034FE',
      '#0079FE',
      '#1AA2FE',
      '#53D0FE',
      '#86F0FE',
      '#FEFEFE',
      '#FEF7C0',
      '#FEE500',
      '#FEBC00',
      '#FE7300',
      '#FE3F00',
      '#C70000',
      '#A00000',
      '#640000'
    ]
  },
  zhail: {
    description: ['niskie', 'niskie', 'niskie', 'średnie', 'średnie', 'średnie', 'wysokie', 'wysokie', 'wysokie'],
    legend: [10, 20, 30, 40, 50, 60, 70, 80, 90],
    hex: ['#00007D', '#0100c6', '#0079fe', '#53d0ff', '#ffffff', '#ffe500', '#ff7300', '#c70100', '#800000']
  }
};