import chroma from 'chroma-js';

const temp_palette = [
  '#4B0082',
  '#8A2BE2',
  '#4169E1',
  '#1E90FF',
  '#00BFFF',
  '#8FBC8F',
  '#7CFC00',
  '#FFFF00',
  '#FF4500',
  '#FF0000',
  '#8B0000'
];

const baro_palette = ['#4B0082', '#8A2BE2', '#4169E1', '#1E90FF', '#7CFC00', '#FFFF00'];

const wind_palette = ['#20d401', '#FFFF00', '#ff8300', '#FF4500', '#ff3100', '#FF0000', '#d00000', '#8B0000'];

const prec_palette = ['#7CFC00', '#00BFFF', '#1E90FF', '#4169E1', '#8A2BE2', '#4B0082'];

const noTempColors = 101;
const noBaroColors = 200;
const noWindColors = 70;
const noPrecColors = 100;

export const tempColors = chroma
  .scale(temp_palette)
  .mode('lch')
  .colors(noTempColors);

export const baroColors = chroma
  .scale(baro_palette)
  .mode('lch')
  .colors(noBaroColors);

export const windColors = chroma
  .scale(wind_palette)
  .mode('lch')
  .colors(noWindColors);

export const precColors = chroma
  .scale(prec_palette)
  .mode('lch')
  .colors(noPrecColors);
