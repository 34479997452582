export const imgwStations = {
  '12100': {
    lat: 54.15833333333333,
    lon: 15.38888888888889
  },
  '12105': {
    lat: 54.20444444444445,
    lon: 16.155277777777776
  },
  '12110': {
    lat: 51.40694444444444,
    lon: 14.975833333333334
  },
  '12115': {
    lat: 54.58833333333334,
    lon: 16.854166666666668
  },
  '12120': {
    lon: 17.534722222222225,
    lat: 54.75361111111111
  },
  '12125': {
    lat: 54.55305555555555,
    lon: 17.756944444444443
  },
  '12130': {
    lat: 54.261944444444445,
    lon: 18.162499999999998
  },
  '12135': {
    lat: 54.603611111111114,
    lon: 18.811944444444446
  },
  '12140': {
    lat: 50.67638888888889,
    lon: 17.388333333333332
  },
  '12150': {
    lat: 52.28138888888889,
    lon: 20.96333333333333
  },
  '12155': {
    lat: 54.33361111111111,
    lon: 18.934444444444445
  },
  '12160': {
    lon: 19.43138888888889,
    lat: 54.161944444444444
  },
  '12170': {
    lat: 50.32972222222222,
    lon: 23.342777777777776
  },
  '12180': {
    lat: 53.385555555555555,
    lon: 23.555
  },
  '12185': {
    lat: 54.06722222222223,
    lon: 21.366666666666667
  },
  '12190': {
    lat: 52.07694444444445,
    lon: 23.109444444444446
  },
  '12195': {
    lat: 54.130833333333335,
    lon: 22.948888888888888
  },
  '12200': {
    lat: 53.92333333333333,
    lon: 14.242222222222221
  },
  '12205': {
    lat: 53.39527777777778,
    lon: 14.622777777777777
  },
  '12210': {
    lon: 15.393333333333333,
    lat: 53.76361111111111
  },
  '12215': {
    lat: 53.717777777777776,
    lon: 16.746666666666666
  },
  '12220': {
    lat: 51.1475,
    lon: 19.40833333333333
  },
  '12230': {
    lon: 16.747222222222224,
    lat: 53.13055555555555
  },
  '12235': {
    lat: 53.71527777777778,
    lon: 17.5325
  },
  '12240': {
    lat: 52.86361111111111,
    lon: 23.724999999999998
  },
  '12250': {
    lon: 18.595555555555553,
    lat: 53.04194444444444
  },
  '12260': {
    lat: 54.39972222222222,
    lon: 18.697777777777777
  },
  '12270': {
    lon: 20.361111111111114,
    lat: 53.10416666666667
  },
  '12272': {
    lat: 53.76861111111111,
    lon: 20.42138888888889
  },
  '12280': {
    lon: 21.58972222222222,
    lat: 53.78916666666667
  },
  '12285': {
    lat: 53.06583333333333,
    lon: 21.53388888888889
  },
  '12290': {
    lat: 53.393055555555556,
    lon: 21.791666666666668
  },
  '12295': {
    lat: 53.10722222222223,
    lon: 23.16222222222222
  },
  '12300': {
    lat: 52.74111111111111,
    lon: 15.277222222222223
  },
  '12310': {
    lon: 14.619722222222222,
    lat: 52.34861111111111
  },
  '12320': {
    lat: 51.8725,
    lon: 16.03777777777778
  },
  '12330': {
    lon: 16.834722222222222,
    lat: 52.416666666666664
  },
  '12340': {
    lat: 49.51638888888889,
    lon: 21.484166666666667
  },
  '12345': {
    lat: 52.19972222222222,
    lon: 18.660277777777775
  },
  '12350': {
    lat: 51.62416666666667,
    lon: 18.704722222222223
  },
  '12360': {
    lon: 19.72583333333333,
    lat: 52.58833333333334
  },
  '12370': {
    lat: 53.56333333333333,
    lon: 21.227777777777778
  },
  '12375': {
    lat: 52.16277777777778,
    lon: 20.961111111111112
  },
  '12380': {
    lat: 51.96138888888889,
    lon: 17.589166666666664
  },
  '12385': {
    lat: 52.18111111111111,
    lon: 22.244722222222222
  },
  '12390': {
    lat: 49.71,
    lon: 18.903055555555554
  },
  '12399': {
    lat: 52.078611111111115,
    lon: 23.621944444444445
  },
  '12400': {
    lat: 51.93,
    lon: 15.524722222222223
  },
  '12410': {
    lat: 49.56888888888889,
    lon: 18.892222222222223
  },
  '12415': {
    lat: 51.192499999999995,
    lon: 16.20777777777778
  },
  '12418': {
    lat: 51.83555555555556,
    lon: 16.534722222222225
  },
  '12420': {
    lat: 49.56194444444444,
    lon: 19.18722222222222
  },
  '12424': {
    lat: 51.10333333333333,
    lon: 16.9
  },
  '12430': {
    lat: 49.58166666666667,
    lon: 19.299444444444447
  },
  '12435': {
    lat: 51.78111111111111,
    lon: 18.080833333333334
  },
  '12440': {
    lat: 49.41777777777777,
    lon: 20.89527777777778
  },
  '12450': {
    lat: 50.43805555555555,
    lon: 16.221944444444443
  },
  '12455': {
    lat: 51.21027777777778,
    lon: 18.55666666666667
  },
  '12460': {
    lat: 49.566111111111105,
    lon: 19.772222222222222
  },
  '12465': {
    lat: 51.718333333333334,
    lon: 19.38722222222222
  },
  '12469': {
    lat: 51.35333333333333,
    lon: 19.866388888888892
  },
  '12470': {
    lat: 49.56472222222222,
    lon: 19.881666666666668
  },
  '12480': {
    lat: 50.41,
    lon: 16.379444444444445
  },
  '12488': {
    lat: 51.56472222222222,
    lon: 21.54361111111111
  },
  '12490': {
    lat: 50.42527777777777,
    lon: 16.51833333333333
  },
  '12495': {
    lat: 51.21666666666667,
    lon: 22.393055555555556
  },
  '12497': {
    lat: 51.55333333333333,
    lon: 23.529444444444444
  },
  '12500': {
    lat: 50.900277777777774,
    lon: 15.78888888888889
  },
  '12510': {
    lon: 15.739722222222222,
    lat: 50.73638888888889
  },
  '12520': {
    lon: 16.61416666666667,
    lat: 50.43694444444444
  },
  '12530': {
    lon: 17.968888888888888,
    lat: 50.62694444444445
  },
  '12540': {
    lat: 50.06111111111111,
    lon: 18.190833333333334
  },
  '12550': {
    lat: 50.81194444444444,
    lon: 19.091666666666665
  },
  '12560': {
    lon: 19.032777777777778,
    lat: 50.24055555555556
  },
  '12566': {
    lat: 50.07777777777778,
    lon: 19.795
  },
  '12570': {
    lon: 20.692222222222224,
    lat: 50.81055555555555
  },
  '12575': {
    lat: 50.03,
    lon: 20.983888888888888
  },
  '12580': {
    lon: 22.042222222222225,
    lat: 50.11083333333333
  },
  '12585': {
    lat: 50.696666666666665,
    lon: 21.715833333333332
  },
  '12590': {
    lat: 50.249722222222225,
    lon: 16.633333333333333
  },
  '12595': {
    lat: 50.69833333333333,
    lon: 23.205555555555556
  },
  '12600': {
    lat: 49.806666666666665,
    lon: 19.002222222222223
  },
  '12610': {
    lat: 50.25472222222222,
    lon: 16.890833333333333
  },
  '12620': {
    lat: 50.340833333333336,
    lon: 18.160833333333333
  },
  '12625': {
    lat: 49.29388888888889,
    lon: 19.960277777777776
  },
  '12630': {
    lat: 49.3275,
    lon: 19.825
  },
  '12640': {
    lat: 49.33222222222223,
    lon: 19.929166666666667
  },
  '12650': {
    lat: 49.2325,
    lon: 19.981944444444444
  },
  '12660': {
    lat: 49.62722222222222,
    lon: 20.68861111111111
  },
  '12670': {
    lon: 21.769166666666667,
    lat: 49.70666666666667
  },
  '12680': {
    lat: 49.22916666666667,
    lon: 19.859444444444446
  },
  '12690': {
    lat: 49.46638888888889,
    lon: 22.341666666666665
  },
  '12695': {
    lat: 49.80416666666667,
    lon: 22.771944444444443
  },
  '12750': {
    lat: 49.4375,
    lon: 19.148333333333333
  },
  '12770': {
    lat: 49.79388888888889,
    lon: 19.107222222222223
  },
  '12780': {
    lat: 49.30555555555555,
    lon: 19.929722222222225
  },
  '12790': {
    lat: 49.391666666666666,
    lon: 19.884166666666665
  },
  '12810': {
    lat: 49.87,
    lon: 19.685277777777777
  },
  '12820': {
    lat: 49.67222222222222,
    lon: 19.413888888888888
  },
  '12830': {
    lat: 49.27777777777778,
    lon: 19.870833333333334
  },
  '12840': {
    lat: 49.50805555555556,
    lon: 19.115000000000002
  },
  '12850': {
    lat: 49.42583333333333,
    lon: 19.008055555555554
  },
  '12860': {
    lat: 49.45666666666667,
    lon: 19.14527777777778
  },
  '12870': {
    lat: 49.715833333333336,
    lon: 19.374444444444446
  },
  '12880': {
    lat: 49.87722222222222,
    lon: 19.511111111111113
  },
  '12890': {
    lat: 49.64861111111111,
    lon: 19.155555555555555
  },
  '12910': {
    lat: 49.20111111111111,
    lon: 20.07138888888889
  },
  '12920': {
    lat: 49.425,
    lon: 20.723055555555554
  },
  '12930': {
    lat: 49.33027777777778,
    lon: 20.03527777777778
  },
  '12950': {
    lat: 49.43388888888889,
    lon: 20.025555555555556
  },
  '12960': {
    lat: 49.48638888888889,
    lon: 20.055
  },
  '12970': {
    lat: 49.859722222222224,
    lon: 20.530555555555555
  },
  '12980': {
    lat: 49.66361111111111,
    lon: 20.054444444444446
  },
  '12990': {
    lat: 49.73638888888889,
    lon: 20.590555555555554
  },
  '12010': {
    lat: 49.972500000000004,
    lon: 20.03388888888889
  },
  '12030': {
    lat: 52.830555555555556,
    lon: 19.235555555555557
  },
  '12020': {
    lat: 54.43055555555555,
    lon: 16.368333333333332
  },
  '12070': {
    lat: 52.85194444444445,
    lon: 15.843611111111112
  },
  '12040': {
    lat: 54.487500000000004,
    lon: 17.22861111111111
  },
  '12050': {
    lat: 52.88111111111111,
    lon: 15.98361111111111
  },
  '12060': {
    lat: 54.21638888888889,
    lon: 16.51638888888889
  },
  '12080': {
    lat: 51.652499999999996,
    lon: 15.111388888888888
  },
  '12090': {
    lat: 54.37111111111111,
    lon: 18.486666666666668
  }
};
