import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill } from 'ol/style';
import hexToRgba from 'hex-to-rgba';
import { config } from 'rx';

export const createPolygon = (features, map, UIType, optionals) => {
  const newFeatures = features.map((feature, i) => {
    const newFeature = new Feature(new Polygon([feature.path]));
    newFeature.index = i;
    newFeature.className = feature.UIType + '-style';
    newFeature.mapName = feature.UIType;
    newFeature.meta = {
      ...feature.optional_params
    };
    return newFeature;
  });

  const outside = new VectorLayer({
    visibility: true,
    renderMode: 'image',
    zIndex: config.layerIndex,
    source: new VectorSource({
      features: newFeatures,
      useSpatialIndex: false
    }),
    style: item => {
      const polygon = features[item.index];
      return new Style({
        stroke: new Stroke({
          color: hexToRgba(polygon.color, polygon.opacityStroke),
          width: polygon.strokeWidth
        }),
        fill: new Fill({
          color: hexToRgba(polygon.color, polygon.opacityFill)
        }),
        zIndex: polygon.zIndex
      });
    }
  });

  outside.className = UIType + '-style';
  outside.meta = {
    ...optionals,
    UIType
  };
  map.addLayer(outside);
  //global.RO_LAYERS.getLayers().push(outside);
};
