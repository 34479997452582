import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';

import { cssToHtmlChar } from '@ro-utils/common/helpers';

import { Fill, Stroke, Style, Text } from 'ol/style';
import { transform } from 'ol/proj';
import MultiPoint from 'ol/geom/MultiPoint';

let styles = {
  synop: [],
  snow: [],
  'estofex-marker': []
};

const setStyle = (item, options) => {
  const { type, offset, unit, uitype, fontSize, fontWeight, fontFamily, asFill } = options;
  const geometry = new Point(transform([item.lon, item.lat], 'EPSG:4326', 'EPSG:3857'));
  const content = item.icon || item[type];
  const contentText = item.icon
    ? cssToHtmlChar(item.icon)
    : `${item[type]}${unit || (item.units && item.units[type]) || ''}`;
  const text =
    content &&
    new Text({
      overflow: false,
      textAlign: 'center',
      textBaseline: 'middle',
      font: `${fontWeight || 'bold'} ${fontSize || '11px'} ${item.icon ? 'weathericons' : fontFamily || 'tahoma'}`,
      cursor: 'pointer',
      text: contentText,
      fill: new Fill({ color: asFill ? item.color || (item.colors && item.colors[type]) : '#000' }),
      stroke: new Stroke({
        color: asFill ? 'transparent' : item.color || (item.colors && item.colors[type]),
        width: 5
      }),
      offsetX: 0,
      offsetY: offset,
      rotation: 0,
      padding: [1, 4, 1, 4],
      backgroundFill: new Fill({ color: asFill ? 'transparent' : item.color || (item.colors && item.colors[type]) })
    });

  const style = new Style({
    text: text,
    geometry
  });

  styles[uitype].push(style);

  return false;
};
export const textMarker = (options, callback) => {
  styles = {
    synop: [],
    snow: [],
    'estofex-marker': []
  };
  const coordinates = options.item.map((item, i) => setStyle(item, options));
  const geometry = new MultiPoint(coordinates);
  const multipointFeature = new Feature(geometry);

  const vectorSource = new VectorSource({
    features: [multipointFeature],
    useSpatialIndex: false
  });

  const layer = new VectorLayer({
    renderMode: 'image',
    source: vectorSource,
    opacity: 1,
    style: styles[options.uitype],
    zIndex: options.layerIndex
  });

  layer.meta = {
    UIType: options.uitype
  };

  global.RO_MAP.addLayer(layer);
  //global.RO_LAYERS.getLayers().push(layer);
  callback && callback();
};
