import moment from 'moment';

export const RADAR_FORMAT = 'YYYYMMDDHHmm';
export const ESTOFEX_FORMAT = 'YYYYMMDDHH';
export const LIGHTNINGS_DATEFORMAT = 'DD/MM/YYYY HH:mm:ss';
export const WARNINGS_FORMAT = 'DD/MM/YYYY HH:mm';
export const RADAR_START = '2017-05-18';
export const STORAGE_NAME = 'roStorage_v3.0';

export const SYNOP_TEMP = 'temperatura';
export const SYNOP_PRESS = 'cisnienie';
export const SYNOP_WIND = 'predkosc_wiatru';
export const SYNOP_PREC = 'suma_opadu';

export const LAYER_TILE = 'tile';
export const LAYER_RADAR = 'radar';
export const LAYER_ESTOFEX = 'estofex';
export const LAYER_ESTOFEX_MARKER = 'estofex-marker';
export const LAYER_SKYPREDICT = 'skypredict';
export const LAYER_SNOW_COVER = 'snow';
export const LAYER_SYNOP = 'synop';
export const LAYER_DETECTOR = 'detector';

export type LayerName =
  | typeof LAYER_RADAR
  | typeof LAYER_ESTOFEX
  | typeof LAYER_ESTOFEX_MARKER
  | typeof LAYER_SKYPREDICT
  | typeof LAYER_SNOW_COVER
  | typeof LAYER_SYNOP
  | typeof LAYER_DETECTOR;

export const SYNOPS = [SYNOP_TEMP, SYNOP_PRESS, SYNOP_WIND, SYNOP_PREC];

export const RADAR_START_DATE = moment(RADAR_START);
