import lensPath from 'ramda/src/lensPath';
import view from 'ramda/src/view';
import set from 'ramda/src/set';
import moment from 'moment';
import geo from 'mt-geo';
import queryString from 'query-string';
import { RADAR_FORMAT } from '@ro-utils/constant';
import { setCoord, animationByCoord, coordTransformed } from '@ro-utils/common/map/events';

import { LAYER_RADAR, LAYER_TILE, LIGHTNINGS_DATEFORMAT } from '@ro-utils/constant';

export const setDataFromUrl = (location, dispatch, oldQueryString) => {
  const query = queryString.parse(location.search);
  if (oldQueryString !== location.search) {
    const radartype = query.radartype || false;
    const date = query.date ? moment(query.date, RADAR_FORMAT) : false;

    radartype &&
      dispatch.ro.setMapType({
        radartype,
        noEffect: true
      });

    dispatch.ro.setDate({
      date,
      noEffect: true
    });

    const newLocation = query.location ? decodeURI(query.location) : false;
    if (newLocation) {
      const splittedLocation = newLocation && newLocation.split('|');
      const coordinates = coordTransformed([Number(splittedLocation[0]), Number(splittedLocation[1])]);

      dispatch.ro
        .setCurrentLocation({
          ...setCoord([Number(splittedLocation[0]), Number(splittedLocation[1])], '', {
            zoom: Number(splittedLocation[2])
          }),
          noEffect: true
        })
        .then(() => {
          animationByCoord(coordinates, Number(splittedLocation[2]));
        });
    }
  }
};

export const getUrlString = query => {
  const { location, date, radartype } = query;

  const dateSlug = date ? `${moment(date).format(RADAR_FORMAT)}` : '';

  const parsedQuery = {
    location: location || undefined,
    date: dateSlug || undefined,
    radartype: radartype || undefined
  };
  const { protocol, host, pathname } = window.location;
  return {
    fullUrl: `${protocol}//${host}${pathname}?${queryString.stringify(parsedQuery)}`,
    queryString: `?${queryString.stringify(parsedQuery)}`
  };
};
export const asString = data => JSON.stringify(data);
export const cssToHtmlChar = string => String.fromCharCode(parseInt(string.replace('/', ''), 16));

export const isObjectEmpty = obj => Object.keys(obj).length;
export const msToMph = (ms, prec = 0) => Math.round(parseFloat(2.236936292 * ms), Number(prec));
export const msToKmh = (ms, prec = 0) => Math.round(parseFloat(3.6 * ms), Number(prec));
export const changeFormat = (date, format) => moment(date).format(format);
export const path = (item, path) => view(lensPath(path), item);
export const setIn = (item, path, value) => set(lensPath(path), value, item);

export const distance = (lat1, lon1, lat2, lon2) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 20000 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

export const upperCase = str => str.replace(/(^|\s)\S/g, x => x.toUpperCase());
export const getCoordFromDms = str => geo.parseDMS(`${str.replace('&deg', '°')}`);
export const toTitleCase = str => {
  const lower = String(str).toLowerCase();
  const splitted = lower.split('-');
  const first = splitted[0];
  const second = splitted[1] ? `-${upperCase(splitted[1])}` : '';
  return `${upperCase(first)}${second}`;
};

export const getTimeDiff = (start, end) => {
  var ms = moment(start, LIGHTNINGS_DATEFORMAT).diff(moment(end, LIGHTNINGS_DATEFORMAT));
  var d = moment.duration(ms);
  var u = Math.floor(d.asHours()) + moment.utc(ms).unix();
  return u;
};

export const createElementFromHTML = (htmlString, element) => {
  const div = document.createElement(element);
  div.innerHTML = htmlString.trim();
  return div.firstChild;
};

export const getLayers = map => ({
  path: lensPath(['meta', 'UIType']),
  layers: map.getLayers()
});

export const setLayerVisibility = (visible, type, map) => {
  const layerProps = getLayers(map);
  if (!type) {
    layerProps.layers.forEach(lyr => {
      return view(layerProps.path, lyr) !== LAYER_RADAR && lyr.class !== LAYER_TILE && lyr.setVisible(visible);
    });
  } else {
    layerProps.layers.forEach(lyr => view(layerProps.path, lyr) === type && lyr.setVisible(visible));
  }
};

export const setLayerOpacity = (opacity, type, map) => {
  const layerProps = getLayers(map);
  layerProps.layers.forEach(lyr => view(layerProps.path, lyr) === type && lyr.setOpacity(Number(opacity) / 100));
};

export const removeLayer = (type, map) => {
  const layerProps = getLayers(map);
  layerProps.layers.forEach(lyr => view(layerProps.path, lyr) === type && map.removeLayer(lyr));
};

export const removeLayerByClassName = (type, map) => {
  map.getLayers().forEach(lyr => lyr && lyr.getClassName() === type && map.removeLayer(lyr));
};

export const setLayerIndex = (index, type, map) => {
  const layerProps = getLayers(map);
  layerProps.layers.forEach(lyr => view(layerProps.path, lyr) === type && lyr.setZIndex(Number(index) / 100));
};
