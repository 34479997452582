import { imgwStations as stations } from '@ro-sources/imgwStations';
import { tempColors, baroColors, windColors, precColors } from '@ro-utils/common/colors';

import { SYNOP_TEMP, SYNOP_PRESS, SYNOP_WIND, SYNOP_PREC } from '@ro-utils/constant';

export interface SharedSynopData {
  id_stacji: string;
  stacja: string;
  data_pomiaru: string;
  godzina_pomiaru: string;
  temperatura: string;
  predkosc_wiatru: string;
  kierunek_wiatru: string;
  wilgotnosc_wzgledna: string;
  cisnienie: string;
}

export interface SynopData extends SharedSynopData {
  suma_opadu: string;
}

export interface Station {
  lat: number;
  lon: number;
}

export interface Stations {
  [key: string]: Station;
}

export interface PreparedSynopData extends Station, SharedSynopData {
  colors: {
    [key: string]: string;
  };
  units: {
    [key: string]: string;
  };
  suma_opadu: number;
}

const parseAndRound = (param: string) => Math.round(Number(param));

export const prepareSynopData = (data: SynopData[]) =>
  data &&
  data.map((item: SynopData) => {
    const station: Station = (stations as Stations)[item.id_stacji];

    const colors = {
      [SYNOP_TEMP]: tempColors[parseAndRound(item[SYNOP_TEMP]) + 50],
      [SYNOP_PRESS]: baroColors[parseAndRound(item[SYNOP_PRESS]) - 850],
      [SYNOP_WIND]: windColors[parseAndRound(item[SYNOP_WIND])],
      [SYNOP_PREC]: precColors[parseAndRound(item[SYNOP_PREC])]
    };

    const units = {
      [SYNOP_TEMP]: '°C',
      [SYNOP_PRESS]: 'hPa',
      [SYNOP_WIND]: 'm/s',
      [SYNOP_PREC]: 'mm'
    };

    const preparedData: PreparedSynopData = {
      ...item,
      suma_opadu: parseFloat(item[SYNOP_PREC]),
      lat: station && station.lat,
      lon: station && station.lon,
      colors,
      units
    };

    return preparedData;
  });
