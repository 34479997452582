import { legend_1522792800000 } from './1522792800000';

export const legend_1551652549000 = {
  cmax: {
      description: legend_1522792800000.cmax.description,
      legend: [0, 5, 8, 12, 16, 19, 23, 27, 30, 34, 38, 41, 45, 49, 52, 56, 60],
      hex: [
        '#00007D',
        '#0000C7',
        '#0050EC',
        '#0079FF',
        '#32c8ff',
        '#60dfff',
        '#78ebff',
        '#ffffff',
        '#fffad5',
        '#fff7c0',
        '#ffe500',
        '#ff9900',
        '#ff7300',
        '#ff3f00',
        '#db1500',
        '#c80000',
        '#800000'
      ]
  },
  cappi: {
    description: legend_1522792800000.cappi.description,
    legend: [0, 5, 8, 12, 16, 19, 23, 27, 30, 34, 38, 41, 45, 49, 52, 56, 60],
    hex: [
      '#00007D',
      '#0000C7',
      '#0050EC',
      '#0079FF',
      '#32c8ff',
      '#60dfff',
      '#78ebff',
      '#ffffff',
      '#fffad5',
      '#fff7c0',
      '#ffe500',
      '#ff9900',
      '#ff7300',
      '#ff3f00',
      '#db1500',
      '#c80000',
      '#800000'
    ]
  },
  pac: legend_1522792800000.pac,
  sri: legend_1522792800000.sri,
  eht: legend_1522792800000.eht,
  zhail: legend_1522792800000.zhail
};