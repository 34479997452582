import { legend_1638317339000 } from './legends/1638317339000';
import { legend_1522792800000 } from './legends/1522792800000';
import { legend_1551652549000 } from './legends/1551652549000';
import { defaultLegendParams } from './defaultParams';

/**
 * NEW_MODE_CONFIG = nowa projekcja dla plików radarowych zapisywana w nowym folderze (pojawiła się przy przejściu na metodę pobierania plików z api IMGW res4)
 * plik legend_* oznacza że legenda wyszła w (* = timestamp)
 * klucz będący timestampem w obiekcie rl oznacza legendę obowiązującą do określonej daty, newest oznacza najnowszy - obowiązujący aktualnie
 */

const oldModeParams = {
  dir: 'imgw',
  mode: 'old'
};

const newModeParams = {
  dir: 'imgw_EPSG_3857',
  mode: 'new'
};


const radartypes = ['cmax','cappi', 'pac', 'sri', 'eht', 'zhail'];

let rl = {};

radartypes.forEach(type => {
  rl[type] = {
    default: {
      ...defaultLegendParams[type],
      ...legend_1638317339000[type],
      ...newModeParams
    },
    1522792800000: {
      ...defaultLegendParams[type],
      ...legend_1522792800000[type],
      ...oldModeParams
    },
    1551652549000: {
      ...defaultLegendParams[type],
      ...legend_1551652549000[type],
      ...oldModeParams
    },
    1638317339000: {
      ...defaultLegendParams[type],
      ...legend_1551652549000[type],
      ...newModeParams
    },
  }
})

export default rl;
