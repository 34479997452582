import { toStringHDMS } from 'ol/coordinate';
import { transform } from 'ol/proj.js';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';

export const zoomAnimation = (map, type) => {
  const view = map.getView();
  const zoom = view.getZoom();
  view.animate({
    zoom: zoom + type,
    duration: 200,
    resolution: map.getView().getResolution()
  });
};

export const setCoord = (coord, label, { zoom }) => {
  const transformed = transform(coord, 'EPSG:4326', 'EPSG:3857');
  const hdms = toStringHDMS(transform(transformed, 'EPSG:3857', 'EPSG:4326'));

  return {
    label,
    hdms,
    transformed,
    original: coord,
    zoom
  };
};

export const coordTransformed = coord => {
  return transform(coord, 'EPSG:4326', 'EPSG:3857');
};

export const animationByCoord = (coord, zoom) => {
  global.RO_MAP.getView().animate({ center: coord, duration: 400, zoom: zoom || 8 });
  return coord;
};

export const bindOlEvents = (self, setCurrentLocation, setCurrentRotation) => {
  //self.map.removeEventListener('click');

  self.map.on('click', evt => {
    const coord = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    const zoom = global.RO_MAP.getView().getZoom();
    setCurrentLocation(setCoord(coord, '', { zoom }));
  });

  // self.map.on('pointermove', function(evt) {
  // var hit = evt.map.hasFeatureAtPixel(evt.pixel);
  // this.getTargetElement().style.cursor = hit ? 'pointer' : '';
  // });

  self.map.on('moveend', e => {
    const rotation = self.map.getView().getRotation();
    rotation && setCurrentRotation(rotation);
  });
};

export const resetMapRotation = () => {
  const view = global.RO_MAP.getView();
  view.animate({
    rotation: 0,
    duration: 500
  });
};

export const downloadScreen = e => {
  global.RO_MAP.once('postcompose', function(event) {
    // canvas.getContext('experimental-webgl', { preserveDrawingBuffer: true });
    html2canvas(document.querySelector('#chase-map')).then(canvas => {
      !navigator.msSaveBlob &&
        canvas.toBlob(function(blob) {
          FileSaver.saveAs(blob, 'map.png');
        });
    });
    event.stopPropagation();
    event.preventDefault();
    return false;
  });
  global.RO_MAP.renderSync();
  e.stopPropagation();
  e.preventDefault();
  return false;
};
